import absoluteUrl from "next-absolute-url";
import {
  getCacheRevalidateInterval,
  getDefaultDeliveryType,
  makeTimedGetServerSideProps,
  makeTimedGetStaticProps,
  withCache,
} from "src/server/preloader/utils";
import {shopExists} from "src/server/utils/shopExists";
import {getSite} from "src/server/preloader/site";
import {getFilters} from "src/server/preloader/filters";
import {getAxiosInstance} from "src/server/utils/getAxiosInstance";
import {PrefetchedDataKeys} from "src/server/constants";
import {sanitizeFilters} from "src/core/common/utils";
import {getPromotionalBanners} from "src/server/preloader/promotionalBanners";
import {getDeals} from "src/server/preloader/deals";
import {
  getShowcasedGroups,
  getShowcasedGroupProducts,
} from "src/server/preloader/showcasedGroups";
import {getSiteInCluster} from "src/server/clusters";
import {isKiosk} from "src/server/utils/isKiosk";
import * as Sentry from "@sentry/nextjs";
import fetchGeneralData from "src/server/preloader/generalPage";

const fetchHomeData = async (shop, host, params) => {
  if (!shopExists(shop, host) && !isKiosk(shop, host)) return null;

  try {
    if (!shop) return fetchGeneralData(host);

    const siteConfig = getSiteInCluster(host);
    const axiosInstance = getAxiosInstance(shop, host);

    console.log(`Fetching home page data. Shop: ${shop}, Host: ${host}`);

    const site = await getSite(shop, host);

    const deliveryType = getDefaultDeliveryType(site);

    const [promotionalBanners, filters, dealsData] = await Promise.all([
      getPromotionalBanners(axiosInstance),
      getFilters(axiosInstance, {delivery_type: deliveryType, ...params}),
      getDeals(axiosInstance),
    ]);

    const deals = [...dealsData?.promotions, ...dealsData?.rewards];

    const showcasedGroups = await getShowcasedGroups(filters, params);

    const products = await getShowcasedGroupProducts(axiosInstance, showcasedGroups, {
      delivery_type: deliveryType,
    });

    return {
      site: site,
      shop,
      host,
      siteGroupName: siteConfig.name,
      [PrefetchedDataKeys.FILTERS_CATALOG]: filters.data,
      [PrefetchedDataKeys.DEALS]: deals || null,
      [PrefetchedDataKeys.SHOWCASED_GROUPS]: {
        params: filters.params,
        data: showcasedGroups.map((group, index) => ({
          ...group,
          filters: sanitizeFilters(group.filters?.serialize()),
          products: products ? products[index] : null,
        })),
      },
      [PrefetchedDataKeys.PROMOTIONAL_BANNERS]: promotionalBanners,
      metadata: {
        siteImage: site.site?.banner_url || site.site?.logo_url,
        siteTitle: site.site?.html_title,
        siteDescription: site.site?.meta_description,
        favicon: site.site?.favicon_url,
      },
    };
  } catch (e) {
    console.error("Error fetching home data", e);
    Sentry.setTag("Home Page Prefetch Error", true);
    Sentry.captureException(e);
    return {};
  }
};

export const getHome = withCache(fetchHomeData, ".home");

export const getHomeServerSideProps = makeTimedGetServerSideProps(async context => {
  const {shop, ...params} = context.query;
  const sMaxage = getCacheRevalidateInterval();
  context.res.setHeader("Cache-Control", `s-maxage=${sMaxage}, stale-while-revalidate`);
  const {host} = absoluteUrl(context.req);
  const data = await getHome(shop, host, params);

  return {
    props: {
      ...data,
    },
  };
});

export const getHomeStaticProps = makeTimedGetStaticProps(async context => {
  const {shop} = context?.params || {};

  const data = await getHome(shop, null, {});
  const revalidate = getCacheRevalidateInterval();

  return {
    props: {
      ...data,
    },
    revalidate: revalidate,
  };
});
